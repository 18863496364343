<template>
  <div class="horizontal-layout footer-fixed p-3">
    <b-row class="mb-2">
      <b-col
        lg="6"
        md="8"
        cols="9"
      >
        <mindfuli-logo />
      </b-col>
      <b-col
        lg="6"
        md="4"
        cols="3"
        class="d-flex align-items-center justify-content-end"
      >
        <b-link
          variant="primary"
          class="font-weight-bold d-block"
          @click.native="$auth.logout()"
        >
          Logout
        </b-link>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col
        xl="8"
        offset-xl="2"
        lg="10"
        offset-lg="1"
        md="12"
      >
        <router-view />
      </b-col>
    </b-row>

    <footer
      class="footer footer-light"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
} from 'bootstrap-vue'
import MindfuliLogo from '@core/layouts/components/Logo.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'

export default {
  name: 'SidePaneContainer',
  components: {
    AppFooter,
    BRow,
    BCol,
    BLink,
    MindfuliLogo,
  },
}
</script>

<style lang="scss">
@import "./src/@core/scss/base/themes/bordered-layout.scss";
</style>
