<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-none d-md-block mt-25">
      {{ $t('footer.copyright').toUpperCase() }}  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://mindfuli.com"
        target="_blank"
      >Mindfuli</b-link>
      <span class="d-none d-sm-inline-block">, {{ $t('footer.allRightsReserved') }}</span>
    </span>

    <span class="float-md-right d-block d-md-inline-block">
      {{ $t('footer.needHelp') }} <a :href="`mailto:${supportEmail}`" class="text-primary">{{ supportEmail }}</a>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      supportEmail: 'customersupport@mindfuli.com',
    }
  },
}
</script>
